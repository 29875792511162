import CrewFields from "@/components/Crew/CrewFields/CrewFields";
import { useCreateCrew } from "@/components/Crew/CrewFields/requests/useCreateCrew";
import { useDeleteCrew } from "@/components/Crew/CrewFields/requests/useDeleteCrew";
import { useUpdateCrewName } from "@/components/Crew/CrewFields/requests/useUpdateCrewName";
import { crewStore } from "@/mobx/rootStore";
import { handleApiResponse } from "@/utils/common/api/handleApiResponse";
import {
	ActionIcon,
	Button,
	Combobox,
	Group,
	Input,
	InputBase,
	Stack,
	Title,
	useCombobox,
} from "@mantine/core";
import { IconPencil, IconPlus } from "@tabler/icons-react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export default observer(function CrewSelector() {
	const [editingCrew, setEditingCrew] = useState(null);
	const [submitting, setSubmitting] = useState(false);

	const { trigger: createCrew, isMutating: isCreatingCrew } = useCreateCrew();
	const { trigger: updateCrewName, isMutating: isUpdatingCrew } = useUpdateCrewName({
		crewId: editingCrew?.id,
	});
	const { trigger: deleteCrew, isMutating: isDeletingCrew } = useDeleteCrew({
		crewId: editingCrew?.id,
	});

	const combobox = useCombobox({
		onDropdownClose: () => combobox.resetSelectedOption(),
	});

	useEffect(() => {
		crewStore.fetchMyCrews();
	}, []);

	async function handleSubmitCrew(crew) {
		setSubmitting(true);

		if (crew.id) {
			const apiResponse = await updateCrewName({
				payload: {
					...crew,
				},
			});
			// await dame.patch(endpoints.crew.UPDATE_NAME(crew.id), crew);

			handleApiResponse({
				id: "updateCrewName",
				apiResponse,
				successTitle: "Grupo renombrado con éxito",
				onSuccess: () => {
					crewStore.fetchMyCrews();
					setEditingCrew(null);
				},
			});
		} else {
			const apiResponse = await createCrew({
				payload: {
					...crew,
					kind: "mementos",
				},
			});

			handleApiResponse({
				id: "createCrew",
				apiResponse,
				successTitle: "Grupo creado con éxito",
				onSuccess: async ({ response }) => {
					await crewStore.fetchMyCrews();
					crewStore.selectCrewById(response?.id);
					setEditingCrew(null);
				},
			});
		}

		setSubmitting(false);
		crewStore.fetchMyCrews();
		setEditingCrew(null);
	}

	async function handleDeleteCrew(crew) {
		const apiResponse = await deleteCrew({
			crewId: crew?.id,
		});

		handleApiResponse({
			id: "deleteCrew",
			apiResponse,
			successTitle: "Grupo eliminado con éxito",
			onSuccess: async () => {
				await crewStore.fetchMyCrews();
				crewStore.selectCrewByIndex(0);
				setEditingCrew(null);
			},
		});
	}

	const crewList = crewStore.list ?? [];
	const hasCrews = Boolean(crewList.length);

	// Selector option
	const selectedCrew = crewList.find((_crew) => _crew.id === crewStore.getSelectedCrew("id"));

	return (
		<Stack gap="sm">
			<Group justify="space-between">
				<Title order={4}>Grupo</Title>
				<ActionIcon variant="subtle" onClick={() => setEditingCrew({ id: null })}>
					<IconPlus />
				</ActionIcon>
			</Group>

			{!hasCrews && (
				<Button leftSection={<IconPlus />} onClick={() => setEditingCrew({ id: null })}>
					Crear grupo
				</Button>
			)}

			{hasCrews && (
				<Group wrap="nowrap" justify="space-between">
					<Combobox
						store={combobox}
						withinPortal={false}
						onOptionSubmit={(_crewId) => {
							crewStore.selectCrewById(_crewId);
							combobox.closeDropdown();
						}}
					>
						<Combobox.Target>
							<InputBase
								w={"100%"}
								component="button"
								pointer
								rightSection={<Combobox.Chevron />}
								onClick={() => combobox.toggleDropdown()}
								rightSectionPointerEvents="none"
								multiline
							>
								{selectedCrew ? (
									selectedCrew?.name
								) : (
									<Input.Placeholder>ERR</Input.Placeholder>
								)}
							</InputBase>
						</Combobox.Target>

						<Combobox.Dropdown>
							<Combobox.Options>
								{crewList.map((item) => {
									return (
										<Combobox.Option value={item.id} key={item.id}>
											{item.name}
										</Combobox.Option>
									);
								})}
							</Combobox.Options>
						</Combobox.Dropdown>
					</Combobox>

					<ActionIcon
						style={{
							flexGrow: 0,
						}}
						variant="subtle"
						onClick={() => setEditingCrew(crewStore.selected)}
					>
						<IconPencil />
					</ActionIcon>
				</Group>
			)}

			{/* ********************************************* */}
			{/* Modals */}
			{/* ********************************************* */}

			<CrewFields
				opened={Boolean(editingCrew)}
				crew={editingCrew}
				onClose={() => setEditingCrew(null)}
				onSubmit={(crew) => handleSubmitCrew(crew)}
				onDelete={(crew) => handleDeleteCrew(crew)}
				loading={submitting}
			/>
		</Stack>
	);
});
