import { notifications } from "@mantine/notifications";

/**
 * @param {Object} params
 * @param {string} params.id
 * @param {import("swr/dist/mutation").SWRMutationResponse} params.apiResponse
 * @param {string} [params.successTitle]
 * @param {string} [params.successMessage]
 * @param {function} [params.onSuccess]
 *
 * @example
 *	handleApiResponse({
 *		id: "register",
 *		apiResponse: apiResponse,
 *		successTitle: "Cuenta creada con éxito",
 * 		onSuccess: () => {},
 *	});
 */
export function handleApiResponse({
	id,
	apiResponse = {},
	successTitle,
	successMessage,
	onSuccess = () => {},
}) {
	if (apiResponse?.isError) {
		notifications.show({
			id: id,
			title: "Error",
			message:
				apiResponse?.response?.message ||
				apiResponse?.response?.errorCode ||
				"Ha ocurrido un error desconocido",
			color: "red",
		});
	} else {
		if (successTitle) {
			notifications.show({
				id: id,
				title: successTitle,
				message: successMessage,
				color: "green",
			});
		}

		onSuccess({ response: apiResponse?.response });
	}
}
