import dame from "dame";
import { gatherRequestReturn } from "../hooks/api/_utils/gatherRequestReturn";

export const swrMutator = async (url, options) => {
	const { arg } = options;

	const method = arg.method ?? "post";
	const payload = arg.payload ?? {};

	let resDame;

	if (method === "delete") {
		resDame = await dame[method](url, {
			headers: {
				"Content-Type": "text/plain",
			},
		});
	} else {
		resDame = await dame[method](url, payload);
	}

	// if (isError) throw new Error(response.error);
	return gatherRequestReturn(resDame);
};
