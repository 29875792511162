import { endpoints } from "@/utils/common/requests/endpoints";
import { getUseSwrMutationReturn } from "@/utils/swr/getUseSwrMutationReturn";
import { swrMutator } from "@/utils/swr/mutator";
import useSWRMutation from "swr/mutation";

export function useUpdateCrewName({ crewId }) {
	const res = useSWRMutation(endpoints.crew.UPDATE_NAME(crewId), (url, options) => {
		options.arg.method = "patch";

		return swrMutator(url, options);
	});

	return getUseSwrMutationReturn(res);
}
