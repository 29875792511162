import { endpoints } from "@/utils/common/requests/endpoints";
import { getUseSwrMutationReturn } from "@/utils/swr/getUseSwrMutationReturn";
import { swrMutator } from "@/utils/swr/mutator";
import useSWRMutation from "swr/mutation";

export function useDeleteCrew({ crewId }) {
	const res = useSWRMutation(endpoints.crew.DELETE(crewId), (url, options) => {
		options.arg.method = "delete";
		return swrMutator(url, options);
	});

	return getUseSwrMutationReturn(res);
}
