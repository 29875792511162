/**
 * @returns {import("swr/dist/mutation").SWRMutationResponse}}
 */
export function getUseSwrMutationReturn(ret) {
	return {
		trigger: ret.trigger,
		isMutating: ret.isMutating,
		data: ret.data,
		error: ret.error,
	};
}
